.SkillClass>div{
    
    
    margin-top: 4rem;
    width: 7rem;
    height: 9rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
}
.SkillClass{
    display: grid;
    grid-template-columns: 23% 23% 23% 23% ;
    grid-gap:3rem ;
    margin: auto;
   
   width: 98%;

  margin-bottom: 6rem;
  
}
.Skillmain{
    margin-top: 12rem;
    margin-bottom: 13rem;
    
    width: 75%;
    margin: auto;
}
h3{
    text-align: center;
}
.title{
    width: 7rem;
}
.title>h1{
    box-shadow: inset 0 -0.45em 0#ED6A5A;
    font-size: 2.7rem;
}
@media only screen and (max-width: 600px) {
    .SkillClass{
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-gap:1rem ;
    
        
      
    }
  }

  @media only screen and (max-width: 450px) {
    .SkillClass{
        display: grid;
        grid-template-columns: 40% 40% ;
        grid-gap:1rem ;
    
        
      
    }
  }

  @media only screen and (max-width: 350px) {
    .SkillClass>div{
        
        border: none;
        
      
    }
  }


  @media only screen and (max-width: 270px) {
    .SkillClass{
        display: grid;
        grid-template-columns: 100% ;
        grid-gap:1rem ;
        
        
      
    }
    
  }