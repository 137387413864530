
.contactDiv{
    margin-top: 9rem;
    
    width: 75%;
    margin: auto;
}
.contactDiv>h3{
    text-align: center;
    margin-bottom:10rem;
}   

    .textDiv>h1{
        box-shadow: inset 0 -0.45em 0#ED6A5A;
        font-size: 2.7rem;
        width: 10rem;
    }